import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, Injector, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { EventManagerService } from '../service/event-manager.service';
import { EditComponent } from './edit.component';

@Injectable({ providedIn: 'any' })
export abstract class FormEditCheckChangeComponent extends EditComponent implements OnInit, OnDestroy {
  isChanged: boolean = false;
  isHasExistValue: boolean = false;
  isStartCheckChange: boolean = false;
  checkChange$ = new Subject();
  resolvePromise: any;
  constructor(
    public eventManager: EventManagerService,
  ) {
    super(eventManager)
  }
  ngOnInit() {
    super.ngOnInit();
    this.subsCommon.push(
      this.checkChange$.pipe(
        debounceTime(500),
        ).subscribe((event)=> { return this.checkChange();}),
      this.eventManager.subscribe('on-change-input', (res) => {
        this.changeInput(false);
      }),
    )
    if (this.editForm) {
      this.subsCommon.push(
        this.editForm.valueChanges.subscribe((val) => {
          this.checkChange$.next(true);
          this.checkErrorForm();
        }),
      )
    }
  }
  parseData(): void {
    setTimeout(() => {
      this.startCheckChange();
      this.subscriptions.push(
        this.editForm.valueChanges.subscribe((val) => {
          this.changeInput();
        })
      )
    }, 500);
  }
  changeInput(isBroadCast = true) {
    if (!this.isStartCheckChange) {
      return;
    }
    this.isChanged = true;
    if (!isBroadCast) {
      return;
    }
    this.eventManager.broadcast({
      name: 'on-change-input'
    });
  }
  changeInputCalculate() {
    this.changeInput();
    this.startCalculateChange();
  }
  startCalculateChange() {
    this.calculateChange$.next(true);
  }
  startCheckChange() {
    this.isStartCheckChange = true;
    this.checkChange$.next(true);
  }
  checkIsChanged() {
    return this.isChanged;
  }
  checkChange() {
    this.isChanged = this.checkIsChanged();
  }
  checkDisabled() {
    return this.isStartCheckChange && this.isHasExistValue && !this.isChanged;
  }
  checkChangeRouter() {
    return !this.isChanged;
  }
  confirmChangeRouter(isShowWarning = false) {
    return new Promise<boolean>((resolve, reject) => {
      if (this.checkChangeRouter()) {
        resolve(true);
        return;
      } else {
        if (isShowWarning) {
          this.resolvePromise = resolve;
          this.showWarning();
        } else {
            resolve(false);
        }
      }
    });
  }
  clearData() {

  }
  showWarning(message?, title?) {
    message = message || 'Thông tin của bạn chưa được lưu, bạn có muốn thoát?';
    title = title || 'Chưa lưu';
    const properties = {
      message,
      title,
      type: 'confirm',
      isNoDismissAll: true,
      cancelFunc: this.cancelFuncWarning.bind(this),
      func: this.funcWarning.bind(this),
    };
    this.eventManager.broadcast({
      name: 'show-dialog',
      content: properties
    })
  }
  cancelFuncWarning() {
    this.resolvePromise && this.resolvePromise(false);
  }
  funcWarning() {
    this.clearData();
    this.resolvePromise && this.resolvePromise(true);
  }
}
